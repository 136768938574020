import { AppliedTreeFilters, FilterItem } from '../types';

export const processFilters = (filters: {
  baseFilters: FilterItem[];
  treeFilters: AppliedTreeFilters;
}) => {
  let processedBaseFilters = [];
  let acknowledgeDetails = { count: 0, value: true, type: 'ack' };
  filters.baseFilters.forEach((baseFilter) => {
    if (baseFilter.group.name === 'timeFrame') {
      processedBaseFilters.push({
        param: `startDate=${((baseFilter.value ?? []) as string[])[0]}`,
      });
      processedBaseFilters.push({
        param: `endDate=${((baseFilter.value ?? []) as string[])[1]}`,
      });
    } else if (baseFilter.group.name === 'isAcknowledged') {
      acknowledgeDetails.count++;
      acknowledgeDetails.value = baseFilter.value as boolean;
      acknowledgeDetails.type =
        baseFilter.name.toLocaleLowerCase() === 'acknowledged' ? 'ack' : 'unack';
    } else {
      processedBaseFilters.push({
        param: `${baseFilter.group.name}=${
          baseFilter.group.name === 'priorityList'
            ? baseFilter.name.charAt(0).toUpperCase() + baseFilter.name.slice(1).toLowerCase()
            : baseFilter.name
        }`,
      });
    }
  });
  if (acknowledgeDetails.count === 1) {
    processedBaseFilters.push({
      param: `isAcknowledged=${
        acknowledgeDetails.value && acknowledgeDetails.type === 'ack' ? 'True' : 'False'
      }`,
    });
  }

  let processedTreeFilters: { param: string }[] = [];
  let assetIds: string[] = [];
  let siteIds: string[] = [];
  console.log(filters);
  filters.treeFilters.selectedItems.forEach((item) => {
    const id = item.split('|')[2];
    if (item.includes('asset')) {
      assetIds.push(id);
    } else {
      siteIds.push(id);
    }
  });

  if (assetIds.length > 0)
    assetIds.map((id) => processedTreeFilters.push({ param: `assetIdList=${id}` }));
  if (siteIds.length > 0)
    siteIds.map((id) => processedTreeFilters.push({ param: `siteIdList=${id}` }));

  const processedFilters = {
    base: processedBaseFilters,
    tree: processedTreeFilters,
  };

  return processedFilters;
};
